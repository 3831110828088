@font-face {
  font-family: 'Avenir Next';
  src        : local('Avenir Next'), url(../fonts/AvenirNextLTPro-Regular.otf) format('opentype');
}

@import "normalize.css/normalize.css";

html {
  line-height             : 1.15;
  -webkit-text-size-adjust: 100%;
}

body,
html {
  height             : 100vh;
  width              : 100vw;
  overflow           : hidden;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  padding            : 0;
  margin             : 0;
  font-size          : 1rem;
  font-style         : normal;
  line-height        : normal;
  font-family        : "Avenir Next", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight        : 400;
}

body {
  background-color: #ffffff;
  color           : #232323;
}

body * {
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width     : 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin : 0;
  padding: 0;
}

.h1,
h1 {
  font-size  : calc(1.375rem + 1.5vw);
  font-weight: 500;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fs-12 {
  font-size: .75rem !important;
}

.fs-14 {
  font-size: .875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-19 {
  font-size: 1.1875rem !important;
}

.fs-20 {
  font-size: 1.25rem !important;
}

.fs-22 {
  font-size: 1.375rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-28 {
  font-size: 1.75rem !important;
}

.fs-30 {
  font-size: 1.875rem !important;
}

.fs-32 {
  font-size: 2rem !important;
}

.fs-35 {
  font-size: 2.1875rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left : auto !important;
  margin-right: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.text-capitalize {
  text-transform: capitalize;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

.btn {
  background-color: transparent;
  border          : 0;
  padding         : 0.125rem 0;
  font-size       : 1rem;
  cursor          : pointer;

  &-summary {
    width           : 100%;
    height          : 3.25rem;
    padding         : 0;
    background-color: rgba(0, 75, 143, 1);
    border-radius   : 1.25rem 0rem 0rem 1.25rem;
    color           : #FEFEFE;
    font-weight     : 500;
    display         : flex;
    align-items     : center;
    justify-content : center;
  }

  &-reset {
    color: #004B8F;
  }

  &-details {
    display        : flex;
    align-items    : center;
    justify-content: flex-start;
    color          : #666;
    margin-left    : 0.25rem;
    padding        : 0;
  }

  &-back {
    color          : #399DB3;
    display        : flex;
    align-items    : center;
    justify-content: flex-start;
  }

  &-filled {
    background-color: #004B8F;
    color           : #ffffff;
    border-radius   : .5rem;
  }

  &-icon {
    border-radius: 50%;
    overflow     : hidden;
  }

  &-popup-close {
    border       : 1px solid #004B8F;
    padding      : 0.75rem 1.5rem;
    border-radius: .625rem;
  }

  &:disabled,
  &[disabled] {
    opacity       : 0.5;
    cursor        : none;
    pointer-events: none;
  }
}

.checkbox {
  position     : relative;
  padding-right: 1.75rem;
  color        : #989898;
  font-weight  : 600;

  input[type=checkbox] {
    border            : .0625rem solid #989898;
    border-radius     : .25rem;
    background        : none;
    cursor            : pointer;
    line-height       : 0;
    margin            : 0;
    outline           : 0;
    padding           : 0 !important;
    vertical-align    : text-top;
    height            : 1.25rem;
    width             : 1.25rem;
    -webkit-appearance: none;
    position          : absolute;
    right             : 0;
    top               : 50%;
    transform         : translateY(-50%);
  }

  input[type=checkbox]:before {
    content     : '';
    position    : absolute;
    right       : 50%;
    top         : 50%;
    width       : .1875rem;
    height      : .4375rem;
    border      : solid rgba(152, 152, 152, 1);
    border-width: 0 .125rem .125rem 0;
    margin      : -0.0625rem -0.0625rem 0 -0.0625rem;
    transform   : rotate(45deg) translate(-50%, -50%);
    z-index     : 2;
    opacity     : 0;
  }

  input[type=checkbox]:checked:before {
    opacity: 1;
  }

  &.label-right {
    padding-right: unset;
    padding-left : 1.75rem;

    input[type=checkbox] {
      right: unset;
      left : 0;
    }
  }
}

.range-control {
  position: relative;

  input[type=range] {
    display           : block;
    width             : 100%;
    margin            : 0;
    -webkit-appearance: none;
    outline           : none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    position        : relative;
    height          : .125rem;
    border          : .0625rem solid #C4C4C4;
    border-radius   : 50%;
    background-color: #C4C4C4;
    box-shadow      : inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  input[type=range]::-webkit-slider-thumb {
    position           : relative;
    top                : -0.625rem;
    width              : 1.375rem;
    height             : 1.625rem;
    -webkit-appearance : none;
    background-color   : transparent;
    cursor             : pointer;
    background-image   : url(../images/slider-thumb.png);
    background-position: center center;
    background-repeat  : no-repeat;
    background-size    : contain;
    box-shadow         : none;
  }

  output {
    position           : absolute;
    left               : 0%;
    top                : 1.75rem;
    padding            : 0.5rem 0.5rem;
    font-size          : .875rem;
    color              : #ffffff;
    background-color   : #004B8F;
    border-radius      : 1.5625rem;
    // transform       : translateX(-50%);
    white-space        : nowrap;
  }

  &>mark {
    background-color: transparent;
    color           : #989898;
    font-size       : 1rem;
    position        : absolute;
    top             : .5rem;
    left            : 50%;
    transform       : translateX(-50%);
    z-index         : -1;

    &.first-mark {
      left     : 0%;
      transform: unset;
    }

    &.last-mark {
      left     : unset;
      right    : 0%;
      transform: unset;
    }
  }

  &:before,
  &:after {
    content         : " ";
    position        : absolute;
    width           : .25rem;
    height          : .25rem;
    border-radius   : 50%;
    background-color: #C4C4C4;
    left            : 0%;
    top             : 50%;
    transform       : translateY(-50%);
    z-index         : -1;
  }

  &:after {
    left : unset;
    right: 0%;
  }
}

.switch-control {
  label {
    color: #989898;
  }

  &.checked {
    label {
      color: #004B8F;
    }
  }

  input[type="checkbox"] {
    position          : absolute;
    width             : 0;
    height            : 0;
    margin            : 0;
    padding           : 0;
    opacity           : 0;
    -ms-appearance    : none;
    -moz-appearance   : none;
    -webkit-appearance: none;
    appearance        : none;
    border            : none;
  }

  span {
    -webkit-appearance : none;
    -moz-appearance    : none;
    -webkit-transition : .4s all;
    transition         : .4s all;
    margin             : 0 .5rem 0 1rem;
    border-radius      : 3.125rem;
    background         : #989898;
    width              : 2.25rem;
    height             : .875rem;
    outline            : 0;
    cursor             : pointer;
    position           : relative;
    -moz-user-select   : none;
    -webkit-user-select: none;
    user-select        : none;
    display            : inline-block;
    top                : .1875rem;
  }

  input[type="checkbox"] {
    &:checked {
      ~span {
        background: #399DB3;
        transition: .25s all;
      }

      ~span {
        &::before {
          transform : translate(18px, 0);
          background: #004B8F;
        }
      }
    }

    ~span::before {
      content      : '';
      transition   : .26s all;
      position     : absolute;
      left         : 0px;
      top          : -0.1875rem;
      bottom       : .125rem;
      right        : auto;
      transform    : translate(0, 0);
      display      : block;
      width        : 1.25rem;
      height       : 1.25rem;
      border-radius: 2.5rem;
      background   : #399DB3;
    }
  }
}

.text-ellipsis {
  white-space  : nowrap;
  text-overflow: ellipsis;
  max-width    : 100%;
  overflow     : hidden;
}

.popup {
  position        : fixed;
  top             : 0;
  left            : 0;
  z-index         : 1000;
  visibility      : hidden;
  width           : 0vw;
  height          : 0vh;
  overflow-x      : hidden;
  overflow-y      : auto;
  outline         : 0;
  background-color: rgba(0, 0, 0, 0.5);

  &-dialog {
    position      : relative;
    width         : auto;
    margin        : 0.5rem 0.75rem;
    pointer-events: none;
    display       : flex;
    align-items   : center;
    min-height    : calc(100% - 1rem);
    transition    : transform 0s ease-in-out;
    transform     : translate(0, -50px);
  }

  &.show {
    visibility: visible;
    width     : 100vw;
    height    : 100vh;

    .popup-dialog {
      transform: none;
    }
  }

  &-content {
    position        : relative;
    display         : flex;
    flex-direction  : column;
    width           : 100%;
    pointer-events  : auto;
    background-color: #ffffff;
    background-clip : padding-box;
    border-radius   : 0.5rem;
    outline         : 0;
    max-width       : 400px;
    margin          : 0 auto;
  }

  &-header {
    display                : flex;
    flex-shrink            : 0;
    align-items            : center;
    justify-content        : space-between;
    padding                : 1rem 1rem;
    border-top-left-radius : 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &-title {
    margin-bottom: 0;
    line-height  : 1.5;
  }

  &-body {
    position: relative;
    flex    : 1 1 auto;
    padding : 1rem;
  }

  &-footer {
    display                   : flex;
    flex-shrink               : 0;
    flex-wrap                 : wrap;
    align-items               : center;
    justify-content           : flex-end;
    padding                   : calc(1rem - 0.5rem * .5);
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius : 0.5rem;
  }
}

#root {
  height  : 100vh;
  width   : 100vw;
  overflow: hidden;
  position: relative;

  &>.App {
    height  : 100%;
    width   : 100%;
    position: relative;

    &>#payout-simulator {
      height    : 100vh;
      width     : 100vw;
      position  : relative;
      overflow-x: hidden;
      overflow-y: auto;

      &>.header {
        &>div {
          padding        : 1rem;
          padding-bottom : 0rem;
          display        : flex;
          flex-direction : column;
          align-items    : flex-start;
          justify-content: center;

          .toolbar {
            display        : flex;
            align-items    : center;
            justify-content: flex-start;
            width          : 100%;
            padding-bottom : 1rem;

            &>span:last-child {
              margin-left: auto;
            }
          }
        }
      }

      &>.body {
        padding         : 0;
        height          : calc(100% - 16.5rem);
        width           : 100vw;
        overflow-x      : hidden;
        overflow-y      : auto;
        background-color: #ffffff;

        &>div {
          height    : 100%;
          width     : 100%;
          position  : relative;
          overflow-x: hidden;
          overflow-y: auto;

          &.empty-policy-view {
            display        : flex;
            align-items    : center;
            justify-content: center;
            text-align     : center;

            &>span {
              padding: 0rem 2rem;

              p {
                margin-top   : 0;
                margin-bottom: 2rem;
              }

              .btn {
                width        : 100%;
                padding      : 1rem;
                border-radius: .9375rem;

                &-reset {
                  margin-top: 4rem;
                }
              }
            }
          }

          &>.policies-total-value {
            position        : sticky;
            top             : 0;
            z-index         : 11;
            background-color: #FFF;
            height          : 4.0625rem;

            &>span {
              width          : 100%;
              display        : flex;
              align-items    : center;
              justify-content: flex-start;
              padding-left   : 1rem;
              padding-right  : 1rem;

              &>span {
                background-color: #F6FBFF;
                padding         : 0.25rem 0rem;
                font-size       : 1.125rem;
                display         : inline-block;
              }

              &:last-child {
                font-size      : 1.5rem;
                font-weight    : 500;
                justify-content: center;
                margin-top     : 0.5rem;
                padding        : 0rem;
              }
            }
          }

          .toolbar {
            position             : sticky;
            top                  : 4.0625rem;
            left                 : 0;
            display              : grid;
            grid-template-columns: 1fr 2fr;
            align-items          : center;
            padding              : 1rem;
            z-index              : 10;
            background-color     : #ffffff;

            &>span:last-child {
              display        : flex;
              align-items    : center;
              justify-content: flex-end;
            }
          }

          .policies {
            padding: 0rem 1rem 1rem 1rem;

            &>.policy {
              margin-bottom   : 1.25rem;
              border-radius   : 0.9375rem;
              background-color: #F6FBFF;
              box-shadow      : 0.125rem 0.125rem 0.3125rem 0rem #DADADA;
              position        : relative;
              overflow        : hidden;

              &>.front {
                display              : grid;
                grid-template-columns: 5rem 1fr 3.125rem;
                align-items          : center;
                column-gap           : 1rem;
                padding-top          : 1rem;
                padding-bottom       : 1rem;
                position             : relative;
                border-radius        : 0.9375rem;
                z-index              : 1;
                background-color     : #F6FBFF;
                transition           : transform 0.2s ease-in;

                &:after {
                  content         : " ";
                  position        : absolute;
                  width           : .1875rem;
                  height          : calc(100% - 2.5rem);
                  top             : 50%;
                  right           : -0.0625rem;
                  transform       : translateY(-50%);
                  border-radius   : .125rem;
                  background-color: #13C359;
                }

                &>span:first-child {
                  display       : flex;
                  flex-direction: column;
                  gap           : 0.25rem;
                  color         : #989898;
                  font-size     : 0.875rem;
                  padding-left  : 1rem;

                  &>span.policy-provider-logo {
                    display            : block;
                    width              : 1.5rem;
                    height             : 1.5rem;
                    border-radius      : 50%;
                    background-color   : #999;
                    background-position: center center;
                    background-repeat  : no-repeat;
                    background-size    : cover;
                  }
                }

                &>span:nth-child(2) {
                  display       : flex;
                  flex-direction: column;
                  gap           : 0;
                  color         : #989898;
                  font-size     : 0.75rem;

                  &>span.policy-present-value {
                    color    : #232323;
                    font-size: 1.5625rem;
                  }

                  &>span.policy-payment-streams {
                    font-size : 0.875rem;
                    text-align: right;

                    &>span.ps-count {
                      color: rgba(35, 35, 35, 1);
                    }
                  }
                }

                &>span:last-child {
                  display        : flex;
                  align-items    : center;
                  justify-content: center;
                  padding-right  : 1rem;
                }
              }

              &>.back {
                width                : calc(100% - .125rem);
                height               : calc(100% - .125rem);
                position             : absolute;
                top                  : 50%;
                left                 : 50%;
                transform            : translate(-50%, -50%);
                background-color     : #004B8F;
                z-index              : 0;
                border-radius        : 0.9375rem;
                display              : grid;
                grid-template-columns: 1fr 6rem;
                align-items          : center;
                justify-content      : flex-end;
                color                : #ffffff;

                &>span:first-child {
                  display        : flex;
                  justify-content: flex-end;
                  align-items    : center;
                  position       : relative;
                  height         : 100%;
                  padding-right  : 0.75rem;

                  &:after {
                    content         : " ";
                    height          : 100%;
                    width           : .0625rem;
                    right           : 0;
                    top             : 0;
                    background-color: #ffffff;
                    position        : absolute;
                  }

                  &>span {
                    display        : flex;
                    flex-direction : column;
                    align-items    : flex-end;
                    justify-content: center;
                    gap            : 0.25rem;

                    &>span {
                      width     : 100%;
                      text-align: left;
                    }

                    &>span:nth-child(2) {
                      font-size: 0.875rem;
                    }

                    &>span.policy-progress-bar {
                      position        : relative;
                      height          : .125rem;
                      border-radius   : .9375rem;
                      background-color: rgba(255, 255, 255, 0.5);
                      margin-top      : 0.25rem;

                      &>span.track {
                        background-color: rgba(246, 251, 255, 1);
                        position        : absolute;
                        left            : 0;
                        top             : 50%;
                        transform       : translateY(-50%);
                        width           : 0%;
                        height          : 100%;
                      }
                    }
                  }
                }

                &>button {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }

      &>.footer {
        position        : fixed;
        bottom          : 0;
        left            : 0;
        width           : 100%;
        box-shadow      : 0rem 0.25rem 1.625rem 0rem #989898;
        z-index         : 100;
        background-color: #ffffff;

        .selected-ps {
          height         : 4.375rem;
          display        : flex;
          align-items    : center;
          justify-content: flex-start;
          padding        : 0rem 1rem;

          ul {
            list-style     : none;
            padding        : 0;
            margin         : 0;
            width          : 100%;
            display        : flex;
            align-items    : center;
            justify-content: flex-start;
            white-space    : nowrap;
            overflow       : auto;
            position       : relative;
            gap            : 1rem;

            li {
              display       : flex;
              flex-direction: column;
              gap           : 0.25rem;
              position      : relative;

              &:after {
                content         : " ";
                position        : absolute;
                right           : -0.5625rem;
                bottom          : 0%;
                width           : 1px;
                height          : 50%;
                background-color: #989898;
              }

              &:last-child {
                &::after {
                  content: none;
                }
              }

              &>span {
                &:first-child {
                  padding         : 0.25rem 0.5rem;
                  color           : #989898;
                  background-color: #F6FBFF;
                  border-radius   : 1.25rem;
                  font-size       : .875rem;
                  margin-right    : auto;
                }

                &:nth-child(2) {
                  display    : flex;
                  align-items: center;
                  gap        : 0.5rem;

                  &>span {
                    padding      : 0.25rem 0.5rem;
                    border-radius: 1.25rem;
                    border       : .0625rem solid #989898;
                    font-size    : 0.875rem;
                    color        : #232323;
                  }
                }
              }
            }
          }
        }

        .summary {
          background: linear-gradient(180deg, #E1ECFE 0%, #F2F7FF 100%);
          height    : 8.3125rem;
          position  : relative;

          &>div {
            height               : calc(100% - 2rem);
            display              : grid;
            grid-template-columns: calc(100% - 10rem) 10rem;
            padding              : 1rem 0rem 1rem 1rem;
            align-items          : flex-start;

            &>span {
              &:first-child {
                display        : flex;
                flex-direction : column;
                align-items    : flex-start;
                justify-content: flex-start;
                gap            : 0.3125rem;
                width          : 100%;

                &>span:nth-child(2) {
                  height        : 2.5rem;
                  width         : calc(100% - 0.5rem);
                  overflow      : hidden;
                  white-space   : nowrap;
                  display       : flex;
                  align-items   : center;
                  letter-spacing: -0.0625rem;
                }
              }

              &:last-child {
                width          : 100%;
                height         : 100%;
                display        : flex;
                flex-direction : column;
                align-items    : flex-end;
                justify-content: flex-start;
                gap            : 0.625rem;

                &>span {
                  width          : 100%;
                  display        : flex;
                  align-items    : center;
                  justify-content: flex-start;
                  color          : #666;
                  font-weight    : 100;
                  white-space    : nowrap;
                }
              }
            }
          }

          &>span.min-payout-error-message {
            color     : #F00;
            position  : absolute;
            bottom    : 10px;
            left      : 50%;
            transform : translateX(-50%);
            width     : 100%;
            text-align: center;
            font-size : 0.85rem;
          }
        }
      }

      .p-d {
        position        : fixed;
        height          : calc(100vh - 12.6875rem);
        width           : 100vw;
        top             : 0%;
        left            : 0%;
        transform       : translateX(100%);
        transition      : transform 0.2s ease-in;
        z-index         : 20;
        background-color: #F0F6FE;

        &.show {
          transform: translateX(0%);
        }

        &-h {
          background-color: #E4EEFE;
          box-shadow      : 0rem .25rem .25rem 0rem #67676740;
          position        : relative;
          z-index         : 1;

          .toolbar {
            display        : flex;
            align-items    : center;
            justify-content: flex-start;
            padding        : 1rem;

            &>span:last-child {
              margin-left: auto;
            }
          }

          .content {
            display              : grid;
            grid-template-columns: 4.5rem calc(100% - 10.5rem) 4.5rem;
            align-items          : center;
            justify-content      : flex-start;
            padding              : 0rem 1rem 1.5rem;
            gap                  : 0.75rem;

            &>span:first-child {
              display        : flex;
              flex-direction : column;
              align-items    : center;
              justify-content: center;
              gap            : 0.25rem;
              color          : #7B7B7B;

              &>span.policy-provider-logo {
                display            : block;
                width              : 1.5rem;
                height             : 1.5rem;
                border-radius      : 50%;
                background-color   : #999;
                background-position: center center;
                background-repeat  : no-repeat;
                background-size    : cover;
              }
            }

            &>span:nth-child(2) {
              display       : flex;
              flex-direction: column;
              gap           : 0.5rem;
              font-size     : .875rem;

              &>span:first-child {
                display        : flex;
                align-items    : center;
                justify-content: flex-start;

                .btn-details {
                  color: #989898;
                }
              }

              &>span.policy-present-value {
                font-size: 1.75rem;
              }

              &>span.policy-payment-streams {
                font-size      : 0.875rem;
                display        : flex;
                align-items    : center;
                justify-content: flex-start;

                &>span.ps-count {
                  background-color: #ffffff;
                  padding         : 0.165rem 0.25rem;
                  margin-left     : .375rem;
                  border-radius   : .25rem;
                }
              }
            }

            &>span:last-child {
              display       : flex;
              flex-direction: column;
              gap           : 0.75rem;

              &>button.btn.btn-filled {
                padding: 0.5rem 0.75rem;
              }
            }
          }
        }

        &-b {
          height    : calc(100% - 10.875rem);
          position  : relative;
          overflow-x: hidden;
          overflow-y: auto;

          &>div {
            padding: 2rem 0.75rem 0rem;

            &>span {
              color: #626262;
            }

            .ps {
              padding: 1rem 0rem;

              &-s {
                display              : grid;
                grid-template-columns: 2rem calc(100% - 12rem) 10rem;
                position             : relative;
                border-radius        : .625rem;
                border               : .0625rem solid #989898;
                margin               : 0.5rem 0rem 1.75rem;
                overflow             : hidden;
                background-color     : #F5F9FF;

                &>span {
                  display    : flex;
                  align-items: center;

                  &:first-child {
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    border-right   : .0625rem solid #989898;

                    .checkbox {
                      padding-right: 1.25rem;
                    }
                  }

                  &:nth-child(2) {
                    border-right: .0625rem solid #98989875;

                    &>span {
                      padding       : 1rem 0.5rem 1rem 1rem;
                      display       : flex;
                      flex-direction: column;
                      gap           : 0.5rem;
                      color         : #989898;
                      width         : 100%;

                      &>span {
                        display        : flex;
                        align-items    : center;
                        justify-content: flex-start;

                        &>span {
                          display        : flex;
                          align-items    : center;
                          justify-content: flex-start;

                          &>button {
                            color: #989898;
                          }
                        }
                      }

                      &>span:first-child {
                        display              : grid;
                        grid-template-columns: auto auto;
                        align-items          : center;
                        color                : #232323;
                        height               : 2.625rem;

                        &>span:first-child {
                          border-right : .0625rem solid #232323;
                          padding-right: 0.5rem;
                        }

                        &>span:last-child {
                          padding-left: 0.5rem;
                          line-height : 1.25;
                        }
                      }
                    }
                  }

                  &:last-child {
                    &>span {
                      padding       : 1rem 1rem 1rem 1rem;
                      display       : flex;
                      flex-direction: column;
                      width         : 100%;
                      gap           : 0.75rem;

                      &>span:first-child {
                        color: #399DB3;

                        &>div:nth-child(2) {
                          display        : flex;
                          align-items    : center;
                          justify-content: flex-start;
                          height         : 27px;

                        }

                        .ps-progress-bar {
                          position        : relative;
                          display         : block;
                          width           : 100%;
                          height          : .1875rem;
                          border-radius   : .1875rem;
                          overflow        : hidden;
                          background-color: #C4C4C4;
                          margin-top      : 0.25rem;
                          max-width       : 8.25rem;

                          &>span.track {
                            background-color: #004B8F;
                            position        : absolute;
                            left            : 0;
                            top             : 50%;
                            transform       : translateY(-50%);
                            width           : 0%;
                            height          : 100%;
                            transition      : width 0.2s ease;
                          }
                        }
                      }

                      .btn-icon {
                        position: absolute;
                        right   : 0.25rem;
                        top     : 0.75rem;
                        padding : 0rem 0.5rem;
                      }
                    }
                  }
                }

                &.selected {
                  &>span {
                    &:first-child {
                      background-color: #004B8F;

                      .checkbox {
                        input[type="checkbox"] {
                          border-color: #ffffff;

                          &::before {
                            border-color: #ffffff;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ps-d {
        color: #989898;

        &-row {
          display              : grid;
          grid-template-columns: 11rem 1fr;
          gap                  : 0.5rem;
        }

        &-col {
          padding        : 0.5rem 0rem;
          font-size      : .875rem;
          display        : flex;
          align-items    : center;
          justify-content: flex-start;

          &:first-child {
            display              : grid;
            grid-template-columns: 1.75rem 1fr;
          }
        }
      }

      .ps-b {
        position        : fixed;
        height          : calc(100vh - 12.6875rem);
        width           : 100vw;
        top             : 0%;
        left            : 0%;
        transform       : translateY(100%);
        transition      : transform 0.2s ease-in;
        z-index         : 20;
        background-color: #EFF5FE;

        &.show {
          transform: translateY(0%);
        }

        &-h {
          background-color: #E4EEFE;
          box-shadow      : 0rem .25rem .25rem 0rem #67676740;

          .content {
            display              : grid;
            grid-template-columns: 2.25rem 1fr;
            align-items          : center;
            justify-content      : flex-start;
            padding              : 1rem 1rem 2rem;

            &>span {
              &:nth-child(2) {
                display       : flex;
                flex-direction: column;
                gap           : 1rem;

                &>span {
                  display              : grid;
                  grid-template-columns: 1fr 5.5rem;
                  align-items          : flex-end;

                  .btn-details {
                    color  : #989898;
                    padding: 0.125rem 0rem;
                  }
                }
              }
            }
          }
        }

        &-b {
          height    : calc(100% - 9rem);
          position  : relative;
          overflow-x: hidden;
          overflow-y: auto;

          &>div {
            &:first-child {
              background-color: #F4F8FD;
              padding         : 2rem 0.75rem 1rem;

              &>span {
                &:first-child {
                  display        : flex;
                  align-items    : center;
                  justify-content: space-between;
                  letter-spacing : 0.05rem;

                  .btn-reset {
                    padding       : 0.25rem 0.5rem;
                    letter-spacing: 0.05rem;
                  }

                  .checkbox {
                    &.selected {

                      input[type=checkbox],
                      input[type=checkbox]:before {
                        border-color: #399DB3;
                      }

                      label {
                        color: #399DB3;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2) {
              padding       : 1rem 0.75rem 1rem;
              display       : flex;
              flex-direction: column;
              gap           : 0.5rem;
              margin-bottom : 4rem;

              &>span {
                display: flex;
                padding: 0.5rem;

                &:first-child {
                  color  : #004B8F;
                  padding: 0rem;
                }
              }
            }

            ul {
              list-style                : none;
              padding                   : 1rem 0rem;
              margin                    : 0;
              display                   : grid;
              grid-auto-flow            : column;
              gap                       : 0.75rem;
              align-items               : center;
              justify-content           : flex-start;
              white-space               : nowrap;
              overflow                  : auto;
              -webkit-overflow-scrolling: touch;
              scroll-behavior           : smooth;
              position                  : relative;
              z-index                   : 21;
              -ms-overflow-style        : none;
              scrollbar-width           : none;

              &:-webkit-scrollbar {
                display: none;
              }

              &>* {
                -webkit-transform: translateZ(0px);
              }

              li {
                list-style      : none;
                position        : relative;
                text-align      : center;
                background-color: #D9EDFE;
                padding         : 0.75rem 0.25rem;
                display         : flex;
                flex-direction  : column;
                align-items     : center;
                justify-content : center;
                gap             : 0.4rem;
                border-radius   : .3125rem;
                width           : 6.25rem;

                &>span:first-child {
                  color: #989898;
                }

                &.selected {
                  background-color: #00D6B4;
                  box-shadow      : 0px 4px 4px 0px #00000021;

                  &>span:first-child {
                    color: #232323;
                  }

                  .ps-progress-bar {
                    opacity: 0;
                  }
                }

                &>span:last-child {
                  position : absolute;
                  top      : 100%;
                  left     : 50%;
                  transform: translateX(-50%);
                  width    : calc(100% - .375rem);
                }

                .ps-progress-bar {
                  position        : relative;
                  display         : block;
                  width           : 100%;
                  height          : .375rem;
                  border-radius   : 0rem;
                  overflow        : hidden;
                  background-color: #C4C4C4;
                  margin-top      : 0.25rem;
                  max-width       : 8.25rem;
                  opacity         : 1;

                  &>span.track {
                    background-color: #004B8F;
                    position        : absolute;
                    left            : 0;
                    top             : 50%;
                    transform       : translateY(-50%);
                    width           : 0%;
                    height          : 100%;
                    transition      : width 0.2s ease;
                  }
                }
              }

              &.months {
                gap: 2.25rem;

                li {
                  background-color: transparent !important;
                  padding         : 0rem;
                  width           : auto;
                  box-shadow      : none !important;
                  border-radius   : 0rem;

                  &>span:first-child {
                    font-size: .75rem !important;
                  }

                  &>span:nth-child(2) {
                    background-color: #D9EDFE;
                    padding         : 1rem 0.25rem;
                    display         : inline-block;
                    width           : 3rem;
                    border-radius   : .3125rem;
                  }

                  &.selected {
                    &>span:nth-child(2) {
                      background-color: #00D6B4;
                      box-shadow      : 0px 4px 4px 0px #00000040;

                    }
                  }

                  .ps-progress-bar {
                    height    : .1875rem;
                    margin-top: -0.1875rem;
                    width     : calc(100% - 10px);
                    left      : 50%;
                    transform : translateX(-50%);
                    opacity   : 1 !important;
                  }
                }
              }
            }

            .view-controller {
              display        : flex;
              align-items    : center;
              justify-content: flex-end;
            }
          }
        }
      }

      .loading {
        position        : fixed;
        width           : 100vw;
        height          : 100vh;
        z-index         : 100000;
        background-color: rgba(0, 0, 0, 0.5);
        display         : flex;
        align-items     : center;
        justify-content : center;
        top             : 0;
        left            : 0;
        display         : none;

        &.show {
          display: flex;
        }

        &>span {
          width   : 100px;
          height  : 100px;
          position: relative;

          &>img {
            max-width: 100%;
          }
        }
      }
    }
  }
}